@use "variables";

.auto-width-menu {
    border: none !important;
    gap: 10px;

    span {
        white-space: nowrap;
    }

    .ui-menu__itemwrapper {
        color: variables.$primary-color;
        border-radius: 6px !important;
        overflow: hidden;
        border: 1px solid variables.$primary-color;

        &:hover {
            color: white;
            background-color: variables.$primary-color;
        }
    }

    .auto-width-menu-tab {
        padding: 8px 10px;

        &:hover {
            color: white;
            background-color: variables.$primary-color;
        }
    }

    .auto-width-menu-active-tab {
        background-color: variables.$primary-color;
        color: white;
    }
}