// DEFAULT THEME BRAND COLORS
$light-primary-color: #00427C;
$light-brandColor-50: #ccd9e5;
$light-brandColor-100: #99b3cb;
$light-brandColor-200: #668eb0;
$light-brandColor-300: #336896;
$light-brandColor-400: $light-primary-color;
$light-brandColor-450: $light-primary-color;
$light-brandColor-500: $light-primary-color;
$light-brandColor-600: $light-primary-color;
$light-brandColor-700: #003563;
$light-brandColor-800: #00284a;
$light-brandColor-900: #001a32;
$light-brandColor-1000: #000d19;

// DARK THEME BRAND COLORS
$dark-primary-color: #0088ff;
$dark-brandColor-50: #cce7ff;
$dark-brandColor-100: #99cfff;
$dark-brandColor-200: #66b8ff;
$dark-brandColor-300: #33a0ff;
$dark-brandColor-400: #fafafa;
$dark-brandColor-450: $dark-primary-color;
$dark-brandColor-500: $dark-primary-color;
$dark-brandColor-600: $dark-primary-color;
$dark-brandColor-700: #006dcc;
$dark-brandColor-800: #005299;
$dark-brandColor-900: #003666;
$dark-brandColor-1000: #001b33;

@mixin light-theme {
    --primaryColor: #{$light-primary-color};
    --brandColor50: #{$light-brandColor-50};
    --brandColor100: #{$light-brandColor-100};
    --brandColor200: #{$light-brandColor-200};
    --brandColor300: #{$light-brandColor-300};
    --brandColor400: #{$light-brandColor-400};
    --brandColor450: #{$light-brandColor-450};
    --brandColor500: #{$light-brandColor-500};
    --brandColor600: #{$light-brandColor-600};
    --brandColor700: #{$light-brandColor-700};
    --brandColor800: #{$light-brandColor-800};
    --brandColor900: #{$light-brandColor-900};
    --brandColor1000: #{$light-brandColor-1000};
}

@mixin dark-theme {
    --primaryColor: #{$dark-primary-color};
    --brandColor50: #{$dark-brandColor-50};
    --brandColor100: #{$dark-brandColor-100};
    --brandColor200: #{$dark-brandColor-200};
    --brandColor300: #{$dark-brandColor-300};
    --brandColor400: #{$dark-brandColor-400};
    --brandColor450: #{$dark-brandColor-450};
    --brandColor500: #{$dark-brandColor-500};
    --brandColor600: #{$dark-brandColor-600};
    --brandColor700: #{$dark-brandColor-700};
    --brandColor800: #{$dark-brandColor-800};
    --brandColor900: #{$dark-brandColor-900};
    --brandColor1000: #{$dark-brandColor-1000};
}

#teamsDefault {
    @include light-theme;
}

#teamsDark {
    @include dark-theme;
}