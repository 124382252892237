@use "variables";

.welcome-dialog {
    background-image: url("../../../assets/images/app_background.jpg") !important;
    background-position: center;

    .welcome-dialog-content {
        gap: 50px;

        .ui-text {
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.30);
        }
    }

    .welcome-dialog-thumbnail {
        border: 3px solid white;
        border-radius: 100%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.30);
        transform: scale(1.1);
        margin-bottom: 20px !important;
    }

    .welcome-dialog-subtitle {
        max-width: 400px;
    }

    .welcome-dialog-logo {
        width: 180px;
        height: auto;
        filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.30));
    }

    .welcome-dialog-button {
        padding: 25px;
        border-radius: 8px;
        border: 0;
        color: variables.$orange-color;
        margin-top: 10px;
        gap: 5px;

        .ui-button__content {
            font-size: 1.2rem;
            font-weight: 600;
        }

        .ui-box {
            transform: scale(1.4);
        }

        &:hover {
            background-color: variables.$orange-color;
            color: white;
        }
    }
}