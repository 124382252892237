@use "variables";

$card-size: 280px;

.template-card {
    border-radius: 6px;
    height: fit-content;
    width: 100%;
    overflow: hidden;
    padding: 8px 5px 8px 10px;
    cursor: pointer;
    gap: 2px;
    word-break: break-all;
    box-shadow: variables.$orange-box-shadow;

    #teamsDefault & {
        background-color: white;
    }

    #teamsDark & {
        background-color: #1F1F1F;
        border: variables.$dark-border;
    }

    &:hover {
        .template-card-actions {
            display: flex;
        }
    }

    .template-card-actions {
        display: none;
    }
}