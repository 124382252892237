.share-item-dialog-details-part {
    overflow: hidden;

    .share-item-dialog-details-part-thumbnail-container {
        width: 100%;
        max-width: 300px;

        & > div {
            width: 100%;
        }
    }
}