@media screen and (max-width: 750px) {
    .virtualized-grid {
        min-height: 0;
    }
}

.virtualized-grid {
    display: grid;
    height: fit-content;
    overflow: scroll;
    width: 100%;

    .empty-div {
        display: flex;
        width: 100%;
    }
}