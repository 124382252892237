@use "variables";

@media screen and (max-width: 750px) {
    .navbar {
        gap: 15px;
    }
}

@media screen and (min-width: 750px) {
    .navbar {
        gap: 25px;
    }
}

.navbar {
    width: 100%;
    padding: 15px 25px;
    align-items: center;
    background: variables.$orange-color;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.20);
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    z-index: 1;

    .starvoice-logo {
        width: 145px;
        min-width: 145px;
        max-width: 145px;
        height: auto;
    }

    .navbar-button {
        border-radius: 6px;
        border: 0;
        height: 50px;
        padding-left: 15px;
        padding-right: 15px;

        .ui-button__content {
            font-size: 1rem;
        }

        .ui-box {
            width: 1.7rem;
            height: 1.7rem;

            svg {
                height: 2rem;
                width: 2rem;
            }
        }

        &.navbar-button-active {
            background-color: variables.$primary-color;
            fill: white;
            color: white;
        }

        &:not(.navbar-button-active) {
            #teamsDefault & {
                background-color: variables.$secondary-light-color;
            }

            #teamsDark & {
                background-color: variables.$secondary-dark-color;
            }

            .ui-box {
                svg {
                    #teamsDefault & {
                        color: #EA3627;
                    }

                    #teamsDark & {
                        color: #FFF;
                    }
                }
            }

            .ui-button__content {
                #teamsDefault & {
                    background: variables.$orange-color;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                #teamsDark & {
                    color: #FFF;
                }
            }
        }
    }

    .help-button, .home-button, .enrich-library-button {
        color: white;
        fill: white;
        cursor: pointer;
        flex-shrink: 0;
    }

    .navbar-app-version {
        position: absolute;
        top: 2px;
        right: 3px;
        color: white;
        opacity: 0.4;
    }
}