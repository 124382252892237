@use "variables";

.comment-card {
    border-radius: variables.$border-radius;
    width: 100%;
    padding: 8px 10px;
    overflow: hidden;
    flex-shrink: 0;

    #teamsDefault & {
        background-color: white;
        border: 1px solid #f3f3f3;
        box-shadow: variables.$orange-box-shadow;
    }

    #teamsDark & {
        background-color: #2D2C2C;
        border: variables.$dark-border;
    }
}