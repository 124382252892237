.launchpad-app-dialog {
    .react-northstar-form-item .image-picker-preview {
        border-radius: 4px;
        padding: 10px;
        justify-content: center;

        img {
            border-radius: 8px;
            width: 130px;
            height: 130px;
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
            object-fit: cover;
        }

        #teamsDefault & {
            background-color: rgb(243, 242, 241);
        }

        #teamsDark & {
            background-color: #201F1F;
        }
    }
}