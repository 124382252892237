@use "variables";

@media screen and (min-width: 750px) {
    .item-card {
        min-height: 248px;
    }
}

.item-card {
    height: fit-content;
    width: 100%;
    display: grid;
    border-radius: variables.$outer-border-radius;
    overflow: hidden;
    min-width: 250px;

    &.item-card-inline {
        padding: 0;
        box-shadow: none !important;
        min-height: fit-content;
        border-radius: 0;

        .item-card-picture {
            border-radius: 4px;
        }
    }

    #teamsDefault & {
        background-color: white;
        box-shadow: variables.$orange-box-shadow;
    }

    #teamsDark & {
        background-color: #2D2C2C;
        border: variables.$dark-border;
    }

    .item-card-picture-container {
        height: fit-content;
        width: 100%;
        cursor: pointer;
        position: relative;

        #teamsDefault & {
            background-color: #e0e0e0;
        }

        #teamsDark & {
            background-color: #424141;
        }

        .item-card-picture {
            width: 100%;
            min-height: 130px;
            max-height: 130px;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            transition: all 0.25s ease;
            margin: 0;
        }

        .item-card-badges-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 10px;
            gap: 8px;
        }
    }

    .item-card-content {
        gap: 3px;
        margin-bottom: 5px;
        cursor: pointer;
        padding: 0 10px;

        .item-card-title {
            display: -webkit-box;
            white-space: normal;
            max-width: 100%;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            overflow-wrap: break-word;
            color: variables.$brandColor400;

            &.item-card-title-visited {
                opacity: 0.6;
            }
        }

        .item-card-description {
            color: #AAA;
            display: -webkit-box;
            white-space: normal;
            max-width: 100%;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            overflow-wrap: break-word;
        }
    }

    .item-card-footer {
        gap: 5px;
        padding: 0 8px 7px 5px;

        .item-card-action {
            min-width: 0;
            stroke-width: 0.2;
            color: variables.$primary-color;
            fill: variables.$primary-color;
            stroke: variables.$primary-color;
            flex-shrink: 0;
        }

        .item-card-share-recommended {
            svg {
                .ui-icon__filled {
                    display: inline;

                    path:first-child {
                        fill: variables.$orange-color;
                    }
                }
            }
        }
    }

    .item-card-badge {
        padding: 4px 8px;
        border-radius: 6px;
        color: white;
        gap: 6px;
        overflow: hidden;

        svg {
            flex-shrink: 0;
        }

        .ui-text {
            margin-top: -2px;
        }
    }

    .item-card-replicated-badge {
        background-color: variables.$primary-color;
    }

    .item-card-starred-badge {
        background-color: variables.$orange-color;
    }
}

.recommendation-button {
    color: variables.$orange-color;
}