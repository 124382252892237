@use "variables";

@media screen and (max-width: 750px) {
    .home-view {
        .home-view-content {
            gap: 10px;
        }

        .home-view-searches, .home-message-of-the-day-part, .home-meet-part-container {
            padding: 0 20px 0 20px;
        }

        .home-view-left-content {
            gap: 15px;
            padding-top: 15px;
        }

        .home-view-best-practices-container {
            gap: 3px;
            padding: 0 10px;
        }
    }
}

@media screen and (min-width: 750px) {
    .home-view {
        .home-view-content {
            gap: 30px;
        }

        .home-view-left-content {
            gap: 25px;
            padding: 30px;
        }

        .home-view-best-practices-container {
            gap: 15px;
        }
    }
}

.home-view {
    position: relative;
    overflow: hidden;

    #teamsDefault & {
        background-color: variables.$secondary-light-color;
    }

    #teamsDark & {
        background-color: variables.$secondary-dark-color;
    }

    .home-view-content {
        overflow: hidden;

        .home-view-content-title {
            margin-bottom: 3px;
            white-space: nowrap;
            color: variables.$brandColor400;
        }

        .home-view-content-subtitle {
            margin-bottom: 8px;
            color: variables.$brandColor400;
        }

        .home-view-search-part-title {
            color: variables.$brandColor400;
        }
    }

    .topics-menu {
        border: none !important;

        span {
            white-space: nowrap;
        }
    }

    .home-view-left-content {
        overflow-x: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .home-news-grid {
        gap: 25px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    }

    .home-view-best-practices-container {
        width: 100%;
        overflow: hidden;
    }

    .home-view-meetings-sideview {
        gap: 15px;
        overflow: hidden;

        #teamsDefault & {
            background-color: variables.$secondary-light-color;
        }

        #teamsDark & {
            background-color: variables.$secondary-dark-color;
        }
    }
}