.notification-sent-dialog-icon {
    transform: scale(1.5);
    color: #39b039;
}

.notification-sent-dialog-container {
    height: 180px;
    width: 100%;
    gap: 30px;
    margin-top: 10px;
    cursor: pointer;
}