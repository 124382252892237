$iconSize: 50px;

.user-profile-stat-item {
    .user-profile-stat-item-icon-container {
        justify-content: center;
        align-items: center;
        width: $iconSize;
        height: $iconSize;
        min-width: $iconSize;
        min-height: $iconSize;
        overflow: visible;
    }

    .user-profile-stat-item-description {
        line-height: 16px;
    }

    .user-profile-stat-item-sub-value {
        color: darkgray;
        margin-bottom: 3px;
    }
}