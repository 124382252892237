.graph-video-player, .graph-video-loader {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 4px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.graph-video-loader {
    position: absolute;
    top: 0;
    left: 0;
}