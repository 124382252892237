.share-item-dialog-images-part {
    .share-item-dialog-images-part-grid {
        gap: 20px !important;
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
        height: 100%;

        div {
            min-height: 150px;
        }
    }
}