@use "variables";

@media screen and (max-width: 750px) {
    .news-card {
        min-height: 400px;
        max-height: 400px;
    }
}

.news-card {
    border-radius: variables.$border-radius;
    height: 100%;
    width: 100%;
    overflow: hidden;
    gap: 10px;

    #teamsDefault & {
        background-color: white;
        box-shadow: variables.$orange-box-shadow;
    }

    #teamsDark & {
        background-color: #2d2c2c;
        border: variables.$dark-border;
    }

    .custom-button {
        border: 0;
    }
}