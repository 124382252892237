.file-preview-dialog-content {
    .fullscreen-iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    .remove-onedrive-banner {
        margin-top: -48px;
        height: calc(100% + 48px);
    }
}