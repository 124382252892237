@use "variables";

.notification-template-list {
    gap: 15px;
    border-radius: variables.$outer-border-radius;
    overflow: scroll;
    height: 100%;

    #teamsDefault & {
        border: 1px solid #ffe0d1;
    }

    #teamsDark & {
        background-color: #2d2c2c;
        border: variables.$dark-border;
    }

    .no-template-container {
        padding: 50px 0;
        opacity: 0.5;
    }
}