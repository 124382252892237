@mixin no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.list-container {
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    @include no-select;
}

.list-header-item {
    overflow: hidden;
}

.list {
    width: 100%;
    min-height: 50px;
    max-width: 100%;
    display: flex;
    background: transparent !important;
    position: relative;
    overflow: scroll;
    margin: 0 !important;

    &.list-no-header {
        #teamsDefault & {
            border-top: 1px solid rgb(237, 235, 233);
        }

        #teamsDark & {
            border-top: 1px solid rgb(37, 37, 37);
        }
    }

    .ui-table__row {
        height: 50px;
        min-height: 50px;
        max-width: 100%;

        &:hover {
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
        }
    }

    .ui-table__header {
        left: 0;
        top: 0;
        vertical-align: top;
        float: left;
        z-index: 5;
        position: sticky;

        #teamsDefault & {
            background-color: white !important;
        }

        #teamsDark & {
            background-color: #2D2C2C !important;
        }

        #teamsHighContrast & {
            background-color: black !important;
        }
    }

    .ui-table__row:not(.ui-table__header) {
        background-color: transparent;
        border-top: 0;
    }

    .ui-table__row:not(.ui-table__header):last-child {
        border-bottom: 0;
    }

    .ui-table__cell__content {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        overflow: hidden;
    }
}

.table-filter {
    .table-filter-header {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;

        .header-title {
            #teamsDefault & {
                color: black !important;
            }

            #teamsDark & {
                color: white !important;
            }

            #teamsHighContrast & {
                color: white !important;
            }
        }
    }

    .table-filter-input-container {
        margin-right: 0 !important;
        max-width: 200px;

        .filter-input {
            height: 0;

            input {
                text-align: right;
                height: 25px;
            }
        }
    }

    .ui-menu__itemwrapper:hover, .ui-menu__itemwrapper:active {
        input {
            #teamsDefault & {
                background-color: white !important;
            }
        }
    }

    .ui-menu__itemindicator {
        margin-right: 13px;
    }

    .ui-checkbox {
        padding: 0 !important;
        margin-bottom: 3px;
    }

    .unique-menu-item-check {
        color: transparent;

        &.checked {
            #teamsDefault & {
                color: #5B5FC7;
            }

            #teamsDark & {
                color: #9399F5;
            }

            #teamsHighContrast & {
                color: yellow;
            }
        }
    }
}