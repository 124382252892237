@use "variables";

.menu-blur-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 1000;
    transition: opacity 0.25s ease-out;
    backdrop-filter: blur(10px);
    opacity: 0;

    &.menu-blur-mask-show {
        opacity: 1;
    }

    &.menu-blur-mask-hide {
        opacity: 0;
        visibility: hidden;
    }
}

.menu-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 1000;
    transition: all 0.25s ease-out;

    &.menu-mask-open {
        left: 0;
    }

    &.menu-mask-close {
        left: -100%;
    }
}

.menu {
    position: relative;
    top: 0;
    left: 0;
    width: calc(100% - 100px);
    height: 100%;
    max-width: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 5px;
    color: white;

    #teamsDefault & {
        background: variables.$orange-color;
        box-shadow: 20px 0 60px rgba(0, 0, 0, 0.3);
    }

    #teamsDark & {
        background: variables.$orange-color;
        box-shadow: 20px 0 60px rgba(0, 0, 0, 0.5);
    }

    & > .ui-flex {
        padding: 15px;
    }

    .menu-item {
        width: 100%;
        padding: .65rem;
        transition: all 0.1s;
        white-space: nowrap;
        margin-right: .2rem;
        cursor: pointer;
        border-radius: 8px;

        #teamsDefault & {
            color: white;

            svg {
                fill: white;
            }
        }

        #teamsDark & {
            color: white;

            svg {
                fill: white;
            }
        }

        &.menu-item-selected {
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

            #teamsDefault & {
                background-color: #FFF;
                color: variables.$orange-color;

                svg {
                    fill: variables.$orange-color;
                }
            }

            #teamsDark & {
                background-color: #2A2A2A;
                color: white;

                svg {
                    fill: white;
                }
            }
        }

        &:hover:not(.selected) {
            #teamsDefault & {
                background-color: rgba(255, 255, 255, 0.2);
            }

            #teamsDark & {
                background-color: #2A2A2A;
            }
        }
    }

    .menu-brand-icon {
        width: 150px;
        height: auto;

        #teamsDefault & {
            fill: white;
        }

        #teamsDark & {
            fill: white;
        }
    }

    .menu-app-version {
        position: absolute;
        bottom: 15px;
        right: 15px;
        opacity: 0.7;
    }
}