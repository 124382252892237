@use "variables";

@media screen and (max-width: 750px) {
    .dashboard-view-right-content {
        padding: 20px 0;
    }

    .dashboard-view-profile-container {
        padding: 20px 20px 0px 20px;
    }
}

@media screen and (min-width: 750px) {
    .dashboard-view-right-content {
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 30px;
        height: 100%;
    }

    .dashboard-view-profile-container {
        padding: 30px;
        margin-right: -30px;
    }
}

.dashboard-view {
    overflow-x: hidden;
    overflow-y: scroll;

    #teamsDefault & {
        background-color: variables.$secondary-light-color;
    }

    #teamsDark & {
        background-color: variables.$secondary-dark-color;
    }

    .dashboard-view-right-content {
        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}