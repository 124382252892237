@use "variables";

@media screen and (max-width: 750px) {
    .items-carousel-carousel {
        padding: 0;

        .swiper-slide {
            padding: 10px 20px;
        }
    }
}

@media screen and (min-width: 750px) {
    .items-carousel-carousel {
        padding: 12px;
    }
}

.items-carousel {
    .items-carousel-content-title {
        margin-bottom: 3px;
        white-space: nowrap;
        color: variables.$brandColor400;
    }

    .items-carousel-content-subtitle {
        margin-bottom: 8px;
        color: variables.$brandColor400;
    }

    .items-carousel-container {
        width: 100%;
        overflow: hidden;
        min-height: 244px;
        border-radius: variables.$border-radius;
    }

    .items-carousel-container-empty {
        .ui-text {
            color: darkgray;
            opacity: 0.5;
        }
    }

    .items-carousel-carousel {
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}