.launchpad-app {
    overflow: hidden;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 10px;

    img {
        height: 50px;
        width: 50px;
        border-radius: 10px;
    }

    .ui-text {
        max-width: 100px;
    }

    &:hover {
        cursor: pointer;

        #teamsDefault & {
            background-color: #f4f4f4;
        }

        #teamsDark & {
            background-color: #1e1e1e;
        }
    }
}