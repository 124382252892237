@use "variables";

@media screen and (max-width: 750px) {
    .items-view-content {
        padding-top: 15px;
        gap: 15px;
    }
}

@media screen and (min-width: 750px) {
    .items-view-content {
        padding: 20px 30px 0 30px;
        gap: 15px;
    }
}

.items-view {
    overflow: hidden;

    #teamsDefault & {
        background-color: variables.$secondary-light-color;
    }

    #teamsDark & {
        background-color: variables.$secondary-dark-color;
    }

    .items-view-content {
        overflow: scroll;
    }

    .items-grid-container {
        position: relative;
        overflow: hidden !important;

        &:before {
            content: '';
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            #teamsDefault & {
                box-shadow: inset 0 23px 3px -20px variables.$secondary-light-color;
            }

            #teamsDark & {
                box-shadow: inset 0 23px 3px -20px variables.$secondary-dark-color;
            }
        }
    }
}