.launchpad-button {
    color: white !important;
    fill: white !important;
    cursor: pointer;
    flex-shrink: 0;
}

.launchpad {
    .launchpad-content {
        height: fit-content;
    }

    .launchpad-apps-container {
        width: 100%;
        column-gap: 10px;
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)) !important;
        max-height: 500px;
        overflow-x: hidden;
        overflow-y: scroll;

        #teamsDefault & {
            color: black;
        }

        #teamsDark & {
            color: white;
        }
    }
}