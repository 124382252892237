@use "variables";

.search-bar {
    border-radius: 10px;
    min-height: 42px;

    #teamsDefault & {
        background-color: white;
        box-shadow: variables.$orange-box-shadow;
    }

    #teamsDark & {
        background-color: #2d2c2c;
        border: variables.$dark-border;
    }

    .search-bar-input {
        input {
            border: none !important;
            border-radius: variables.$border-radius !important;
            padding-top: 8px;
            padding-bottom: 8px;

            &::placeholder {
                color: darkgray;
            }
        }
    }

    .search-bar-filter-button {
        background: variables.$orange-color;
        color: white;
        padding: 10px 15px;
        gap: 10px;
        align-items: center;
        border-radius: 0 9px 9px 0;
        cursor: pointer;
        min-height: 42px;
        min-width: 90px;
    }
}