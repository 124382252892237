@use "variables";

.notification-configuration {
    border-radius: variables.$border-radius;
    width: 100%;
    overflow: hidden;

    #teamsDefault & {
        background-color: white;
        box-shadow: variables.$orange-box-shadow;
    }

    #teamsDark & {
        background-color: #2d2c2c;
        border: variables.$dark-border;
    }

    .notification-preview-container {
        overflow: hidden;
        border-radius: 2px;
        padding: 20px;

        #teamsDefault & {
            background-color: #F3F2F1;
        }

        #teamsDark & {
            background-color: #201F1F;
        }
    }
}