@use "variables";

.kpi {
    overflow: visible;
    text-overflow: ellipsis;
    width: 100%;
    min-width: 300px;
    max-width: calc(100vw - 40px);
    min-height: 300px;
    max-height: 300px;
    padding: 0.6rem 1rem 1rem 1rem;
    border-radius: variables.$outer-border-radius;

    #teamsDefault & {
        background-color: white;
        box-shadow: variables.$orange-box-shadow;
    }

    #teamsDark & {
        background-color: #2D2C2C;
        border: variables.$dark-border;
    }
}