@use "variables";

@media screen and (max-width: 750px) {
    .reporting-view {
        .kpis-container {
            .kpis-grid {
                flex-direction: column;
            }
        }
    }
}

@media screen and (min-width: 750px) {
    .reporting-view {
        .kpis-container {
            .kpis-grid {
                display: grid !important;
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
                grid-auto-flow: dense;
            }
        }
    }
}

.reporting-view {
    padding: 20px 0;
    overflow: scroll;

    #teamsDefault & {
        background-color: variables.$secondary-light-color;
    }

    #teamsDark & {
        background-color: variables.$secondary-dark-color;
    }

    .kpis-container {
        gap: 20px;
        overflow: visible;
        padding: 0 20px 0 20px;

        .kpis-grid {
            padding: 10px 0 30px 0;
            gap: 15px !important;
        }

        .blurred-container {
            box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(25px);
            border-radius: 15px;
            padding: 10px 10px 0 10px;
            overflow: visible;

            #teamsDefault & {
                background: rgba(255, 255, 255, 0.6);
            }

            #teamsDark & {
                background: rgba(0, 0, 0, 0.6);
            }

            #teamsHighContrast & {
                background: black;
                border: 1px solid white;
            }
        }
    }
}