@use "variables";

@media screen and (max-width: 750px) {
    .news-dialog {
        .button-color-picker {
            height: 74px;
            width: 25px;
        }
    }
}

@media screen and (min-width: 750px) {
    .news-dialog {
        .button-color-picker {
            margin-top: 5px;
            height: 20px;
            width: 20px;
            padding: 5px;
        }
    }
}

.news-dialog {
    overflow: hidden;
    width: 100%;

    .news-dialog-menu {
        border: 0;
        padding: 6px 20px 15px 20px !important;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
        flex-shrink: 0;
        z-index: 10;
        height: fit-content;

        #teamsDefault & {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }

        #teamsDark & {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
        }
    }

    .button-delete-button {
        height: 32px;
        width: 32px;
        margin-left: -5px;
    }

    .button-color-picker {
        flex-shrink: 0;
        overflow: hidden;
        border: 1px solid darkgray;
        border-radius: 4px;
        position: relative;

        input {
            cursor: pointer;
            position: absolute;
            top: -100px;
            left: -100px;
            height: 200px;
            width: 200px;
            border: 0;
            padding: 0;
        }
    }
}