@use "variables";

.share-item-dialog-home-view {
    img {
        height: calc(100% + 60px);
        width: auto;
        object-fit: cover;
        margin-top: -30px;
        margin-left: -25px;
    }

    .share-item-dialog-home-view-step-badge {
        z-index: 1;
        height: 30px;
        width: 30px;
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        border: 2px solid variables.$primary-color;
        color: variables.$primary-color;
        font-weight: 600;

        #teamsDefault & {
            background-color: white;
        }

        #teamsDark & {
            background-color: #2a2a2a;
        }
    }

    .share-item-dialog-home-view-line {
        z-index: 0;
        position: absolute;
        height: 2px;
        width: calc(100% + 20px);
        background-color: variables.$primary-color;
        top: 14px;
    }

    .share-item-dialog-home-view-arrow {
        z-index: 0;
        position: absolute;
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid variables.$primary-color;
        top: 8px;
        right: -26px;
    }
}