// SETTINGS
$padding: 15px;
$mobile-padding: 15px;

// COLORS
$secondary-light-color: #fffaf6;
$secondary-dark-color: #1f1f1f;
$orange-color: #eb5932;

// BRAND COLORS
$primary-color: var(--primaryColor);
$brandColor50: var(--brandColor50);
$brandColor100: var(--brandColor100);
$brandColor200: var(--brandColor200);
$brandColor300: var(--brandColor300);
$brandColor400: var(--brandColor400);
$brandColor450: var(--brandColor450);
$brandColor500: var(--brandColor500);
$brandColor600: var(--brandColor600);
$brandColor700: var(--brandColor700);
$brandColor800: var(--brandColor800);
$brandColor900: var(--brandColor900);
$brandColor1000: var(--brandColor1000);

// OTHER
$box-shadow-large: 0px 6px 30px 0px rgba(101, 134, 255, 0.20);
$box-shadow-small: 0px 3px 10px 0px rgba(101, 134, 255, 0.10);
$orange-box-shadow: 0 4px 10px rgba(235, 89, 50, 0.15);

// ELEMENTS
$light-border: 1px solid #AAA;
$dark-border: 1px solid #424242;
$border-radius: 10px;
$outer-border-radius: 10px;