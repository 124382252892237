@use "variables";

.dialog-blur-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 10;
    backdrop-filter: blur(10px);
    animation: dialog-blur-mask-open 0.15s ease-out forwards;

    &.dialog-blur-mask-hide {
        animation: dialog-blur-mask-close 0.15s ease-out forwards;
    }
}

.dialog-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 10;

    &.dialog-mask-popup {
        justify-content: center;
        align-items: end;
        animation: dialog-mask-popup-open .25s cubic-bezier(.2, .7, .4, 1) forwards;

        &.dialog-mask-close {
            animation: dialog-mask-popup-close 0.25s ease-in forwards;
        }
    }

    &:not(.dialog-mask-popup) {
        justify-content: center;
        align-items: center;
        animation: dialog-mask-open 0.15s forwards;

        &.dialog-mask-close {
            animation: dialog-mask-close 0.15s forwards;
        }
    }
}

.dialog {
    .dialog-header {
        padding: 20px 25px 5px 25px;
        gap: 10px;
        flex-shrink: 0;
        z-index: 100;
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .dialog-title {
            font-size: 1.3rem;
        }

        &.dialog-header-shadow {
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
            padding-bottom: 20px;
        }

        #teamsDefault & {
            background-color: #FFF;
        }

        #teamsDark & {
            background-color: #2A2A2A;
        }
    }

    .dialog-menu {
        border: 0;
        padding: 0 20px 15px 20px !important;

        #teamsDefault & {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }

        #teamsDark & {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
        }
    }

    .dialog-content-top-padding:not(.dialog-content-no-padding) {
        padding-top: 25px;
    }

    .dialog-content-bottom-padding:not(.dialog-content-no-padding) {
        padding-bottom: 25px;
    }

    .dialog-content:not(.dialog-content-top-padding):not(.dialog-content-no-padding) {
        padding-top: 10px;
    }

    .dialog-content:not(.dialog-content-no-padding) {
        padding-left: 25px;
        padding-right: 25px;
    }

    .dialog-footer {
        padding: 20px 25px 25px 25px;
        gap: 15px;
        z-index: 1;
        flex-shrink: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        &.dialog-footer-shadow {
            #teamsDefault & {
                box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
            }

            #teamsDark & {
                box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.4);
            }
        }
    }

    #teamsDefault & {
        box-shadow: 0 10px 120px rgba(0, 0, 0, 0.3);
        background: white;
    }

    #teamsDark & {
        box-shadow: 0 10px 120px 20px rgba(0, 0, 0, 1);
        background: #2A2A2A;
    }
}

@keyframes dialog-blur-mask-open {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dialog-blur-mask-close {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes dialog-mask-open {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes dialog-mask-close {
    100% {
        opacity: 0;
        transform: scale(0.95);
    }
    0% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes dialog-mask-popup-open {
    0% {
        top: 100vh;
        transform: scale(0.95);
    }
    100% {
        top: 0;
        transform: scale(1);
    }
}

@keyframes dialog-mask-popup-close {
    100% {
        top: 100vh;
        transform: scale(0.95);
    }
    0% {
        top: 0;
        transform: scale(1);
    }
}