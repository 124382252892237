@use "variables";

.item-button {
    padding: 0 !important;
    box-shadow: variables.$box-shadow-small;
    height: fit-content !important;
    min-height: 36px !important;
    border-radius: 4px !important;
    flex-shrink: 0;

    .item-button-content {
        padding: 8px 15px !important;
        overflow: hidden;
    }

    .text-skeleton > span {
        width: 150px;
    }

    &.item-button-fluid {
        .text-skeleton > span {
            width: 100%;
        }
    }

    span.ui-text {
        overflow: visible;
    }
}