@use "variables";
@use "themes";

@media screen and (min-width: 750px) {
    .flex-grid {
        gap: 30px !important;
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)) !important;
    }
}

html, body, #root, #root > .ui-provider, #root > .ui-provider > div {
    width: 100%;
    height: 100%;
    overscroll-behavior-y: contain;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

div,
input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    outline: none !important;
}

.pos-relative {
    position: relative !important;
}

.ui-provider {
    background: transparent !important;
}

.fill-absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.fill-width {
    position: absolute;
    left: 0;
    right: 0;
}

.fill-height {
    position: absolute;
    top: 0;
    bottom: 0;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.h-20px {
    height: 20px !important;
}

.hidden {
    display: none !important;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-disabled {
    cursor: not-allowed !important;
}

.ui-dropdown__item {
    cursor: pointer !important;
}

.ui-dialog__overlay {
    &.open {
        animation: fadein 0.2s;
    }

    &.close {
        animation: fadeout 0.2s;
    }
}

.ui-dialog {
    overflow: visible !important;
}

.delete-btn {
    color: #FF0000 !important;
    flex-shrink: 0 !important;
}

.delete-btn-primary {
    background-color: #FF0000 !important;
    flex-shrink: 0 !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-scroll {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
}

.p-20px {
    padding: 20px !important;
}

.ui-tooltip__content {
    max-width: 100% !important;
}

.top-padding {
    padding-top: variables.$padding;

    .on-mobile & {
        padding-top: variables.$mobile-padding;
    }
}

.bottom-padding {
    padding-bottom: variables.$padding;

    .on-mobile & {
        padding-bottom: variables.$mobile-padding;
    }
}

.y-padding {
    padding-top: variables.$padding !important;
    padding-bottom: variables.$padding !important;

    .on-mobile & {
        padding-top: variables.$mobile-padding !important;
        padding-bottom: variables.$mobile-padding !important;
    }
}

.x-padding {
    padding-left: variables.$padding !important;
    padding-right: variables.$padding !important;

    &.with-scrollbar {
        padding-right: 5px !important;
    }

    .on-mobile & {
        padding-left: variables.$mobile-padding !important;
        padding-right: variables.$mobile-padding !important;

        &.with-scrollbar {
            padding-right: variables.$mobile-padding !important;
        }
    }
}

.stretch-btn {
    padding: 0 !important;
    min-width: 0 !important;
}

.no-wrap {
    white-space: nowrap !important;
}

.datepicker-input {
    input {
        padding-right: 12px;
    }
}

.ui-dropdown__items-list {
    min-width: fit-content;
}

.ui-dialog__header {
    width: 100%;
}

.custom-icon {
    .ui-button & {
        transform: scale(1.3);
    }

    .ui-menu__item & {
        transform: scale(1.3);
    }

    .ui-button:hover &, &:hover {
        &.fui-Icon-filled {
            display: inline;
        }

        &.fui-Icon-regular {
            display: none;
        }
    }
}

.no-shrink {
    flex-shrink: 0 !important;
}

.hide-scrollbar {
    *::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.toolbar {
    padding: 30px;
    width: 100%;
    box-shadow: variables.$box-shadow-large;
}

.fade {
    animation: fadein 0.15s ease-out;
}

.primary-color {
    color: variables.$primary-color !important;
}

.brand-color-400 {
    color: variables.$brandColor400 !important;
}

.form-border {
    border: 1px solid variables.$primary-color;
    border-radius: 8px;
    padding: 10px;
}

.custom-swiper-container {
    overflow: hidden;

    .swiper {
        width: 100%;
        height: 100%;
    }
}

.swiper-slide {
    overscroll-behavior-x: none;
}

.markdown-view {
    min-width: 100%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    a {
        color: variables.$primary-color !important;
    }

    * {
        margin: 0;
        overflow-wrap: break-word;
    }
}

.gradient-button {
    background: variables.$orange-color !important;
}

.northstar-icon-filled {
    svg {
        .ui-icon__filled {
            display: block;
        }
    }
}