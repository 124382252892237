.notification-preview {
    border-radius: 2px;
    padding: 10px 14px;
    width: 352px;
    max-width: 352px;
    gap: 12px;
    overflow: hidden;
    word-break: break-word;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

    .notification-app-icon {
        height: 24px;
        width: 24px;
        border-radius: 100px;
        position: absolute;
        top: 14px;
        left: 18px;

        #teamsDefault & {
            border: 2px solid #FFF;
        }

        #teamsDark & {
            border: 2px solid #242424;
        }
    }

    .notification-title {
        #teamsDefault & {
            color: #242424;
        }

        #teamsDark & {
            color: #FFF;
        }
    }

    .notification-subtitle {
        #teamsDefault & {
            color: #424242;
        }

        #teamsDark & {
            color: #D6D6D6;
        }
    }

    .notification-app-title {
        #teamsDefault & {
            color: #242424;
        }

        #teamsDark & {
            color: #FFF;
        }
    }

    #teamsDefault & {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.05);
    }

    #teamsDark & {
        background-color: #242424;
        border: 1px solid rgba(255, 255, 255, 0.1);
    }
}