@use "variables";

.share-item-dialog {

    .share-item-dialog-header {
        border: 0;
        padding: 0 20px 15px 25px !important;
        flex-shrink: 0;
        z-index: 10;
        gap: 8px;

        #teamsDefault & {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
        }

        #teamsDark & {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
        }

        .share-item-dialog-header-badge {
            color: white;
            height: 16px;
            width: 16px;
            border-radius: 100%;
            font-size: 10px;
            margin-bottom: -2px;
        }
    }

    .share-item-dialog-content {
        padding: 15px 25px;
        overflow: hidden;
    }

    .share-item-dialog-image-picker {
        border-radius: 4px;
        color: darkgray;
        cursor: pointer;
        height: 100%;
        width: 100%;
        flex-shrink: 0;
        overflow: hidden;

        #teamsDefault & {
            background-color: #F3F2F1;
        }

        #teamsDark & {
            background-color: #1E1E1E;
        }
    }

    .share-item-dialog-image {
        height: 100%;
        width: 100%;
        border-radius: 4px;
        cursor: pointer;
        overflow: hidden;
        flex-shrink: 0;

        #teamsDefault & {
            background-color: #F3F2F1;
        }

        #teamsDark & {
            background-color: #1E1E1E;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        .share-item-dialog-image-remove-btn {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
}