@use "variables";

@media screen and (max-width: 750px) {
    .notifications-view {
        padding: 20px;
    }
}

@media screen and (min-width: 750px) {
    .notifications-view {
        padding: 30px;
    }
}

.notifications-view {
    gap: 30px;
    overflow: scroll;

    #teamsDefault & {
        background-color: variables.$secondary-light-color;
    }

    #teamsDark & {
        background-color: variables.$secondary-dark-color;
    }
}