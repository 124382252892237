@use "variables";

$defaultProfilePictureSize: 150px;
$mediumProfilePictureSize: 120px;
$smallProfilePictureSize: 100px;

@media screen and (max-width: 750px) {
    .user-profile {
        width: 100%;
    }
}

@media screen and (min-width: 750px) {
    .user-profile {
        width: 400px;
    }
}

.user-profile-small {
    min-height: 500px;

    .user-profile-background-image-container {
        .user-profile-background-image {
            height: 110px;
        }
    }

    .user-profile-content {
        height: calc(100% - 110px);
    }

    .user-profile-profile-picture-container {
        top: 30px;
    }

    .user-profile-profile-picture-container {
        left: calc(50% - ($smallProfilePictureSize / 2));
        width: $smallProfilePictureSize;
        height: $smallProfilePictureSize;

        .user-profile-profile-picture {
            width: $smallProfilePictureSize;
            max-width: $smallProfilePictureSize;
            height: $smallProfilePictureSize;
            max-height: $smallProfilePictureSize;
        }
    }
}

.user-profile-medium {
    .user-profile-background-image-container {
        .user-profile-background-image {
            height: 180px;
        }
    }

    .user-profile-content {
        height: calc(100% - 180px);
    }

    .user-profile-profile-picture-container {
        top: 80px;
    }

    .user-profile-profile-picture-container {
        left: calc(50% - ($mediumProfilePictureSize / 2));
        width: $mediumProfilePictureSize;
        height: $mediumProfilePictureSize;

        .user-profile-profile-picture {
            width: $mediumProfilePictureSize;
            max-width: $mediumProfilePictureSize;
            height: $mediumProfilePictureSize;
            max-height: $mediumProfilePictureSize;
        }
    }
}

.user-profile-default {
    .user-profile-background-image-container {
        .user-profile-background-image {
            height: 300px;
        }
    }

    .user-profile-content {
        height: calc(100% - 300px);
    }

    .user-profile-profile-picture-container {
        top: 165px;
    }

    .user-profile-profile-picture-container {
        left: calc(50% - ($defaultProfilePictureSize / 2));
        width: $defaultProfilePictureSize;
        height: $defaultProfilePictureSize;

        .user-profile-profile-picture {
            width: $defaultProfilePictureSize;
            max-width: $defaultProfilePictureSize;
            height: $defaultProfilePictureSize;
            max-height: $defaultProfilePictureSize;
        }
    }
}

.user-profile {
    height: 100%;
    border-radius: variables.$outer-border-radius;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;

    #teamsDefault & {
        background-color: white;
        box-shadow: variables.$orange-box-shadow;
    }

    #teamsDark & {
        background-color: #2D2C2C;
        border: variables.$dark-border;
    }

    .user-profile-background-image-container {
        overflow: hidden;

        #teamsDefault & {
            background-color: #ededed;
        }

        #teamsDark & {
            background-color: #363636;
        }

        .user-profile-background-image {
            width: 100%;
            object-fit: cover;
            transform: scale(1.1);
            transition: all 0.25s ease;
        }
    }

    .user-profile-profile-picture-container {
        overflow: hidden;
        border-radius: 100px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        position: absolute;

        #teamsDefault & {
            background-color: #FFF;
            border: 4px solid white;
        }

        #teamsDark & {
            background-color: #363636;
            border: 4px solid #202020;
        }

        .user-profile-profile-picture {
            border-radius: 100px;
            object-fit: cover;
            transition: all 0.25s ease;

            #teamsDark & {
                &.default-image {
                    filter: contrast(0%);
                }
            }
        }
    }

    .user-profile-content {
        padding: 25px 15px 15px 15px;
        gap: 15px;
        overflow: hidden;
    }

    .user-profile-stats-grid {
        margin-left: 10px;
        margin-top: 5px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 30px;

        .user-profile-stat-item-description {
            width: 100px;
        }
    }
}